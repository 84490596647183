<template>
  <section
    class="team-container"
    id="team"
    :style="isPopup ? { filter: 'blur(30px)' } : { filter: 'none' }"
  >
    <div class="team-content">
      <h3 class="header title">Our Team</h3>
      <p class="sub-title">
        Signa X is led by a team with a combined experience of 10+ years across some of the top firms in the world such as Goldman Sachs and Oracle with a proven track record of building, scaling and successful acquisition as entrepreneurs.
      </p>
      <p class="sub-title">
        The founding team is supported by a core team of advisors consisting of innovation having over three decades of experience in technologies such as digital platforms, blockchain and mobile computing.
      </p>
      <div class="section-content">
        <!-- <v-row class="space">
          <v-col lg="6" cols="12" sm="6" md="6" id="team-one">
            <div class="team-section">
              <img
                src="https://asset-signax.b-cdn.net/asset/team/Frame%20218%20(3).png"
              />
              <h4 class="name header">Adhikar Naidu</h4>
              <p class="desig">Co-Founder</p>
              <p class="old-work">Ex-Goldman Sachs</p>
              <p class="clg">London School Of Economics</p>
              <a
                href="https://www.linkedin.com/in/adhikarnaidu/"
                class="mt-2"
                target="_blank"
                ><img
                  src="../assets/team/linkedin.svg"
                  style="width: 20px; cursor: pointer"
              /></a>
            </div>
          </v-col>
          <v-col lg="6" cols="12" sm="6" md="6" id="team-two">
            <div class="team-section">
              <img
                src="https://asset-signax.b-cdn.net/asset/team/Frame%20217%20(5).png"
              />
              <h4 class="name header">Aakarsh Naidu</h4>
              <p class="desig">Co-Founder</p>
              <p class="old-work">Ex-Meta</p>
              <p class="clg">London School Of Economics</p>
              <a
                href="https://www.linkedin.com/in/aakarshnaidu/"
                class="mt-2"
                target="_blank"
                ><img
                  src="../assets/team/linkedin.svg"
                  style="width: 20px; cursor: pointer"
              /></a>
            </div>
          </v-col>
        </v-row> -->
        <div class="global-media">
          <h4 class="heading">Appearances in Global Media</h4>
          <v-row class="global-data" v-if="!isMobileView">
            <v-col lg="2" cols="6" md="3" sm="4">
              <img src="../assets/global/1.svg" />
            </v-col>
            <v-col lg="2" cols="6" md="3" sm="4">
              <img
                src="../assets/global/2.png"
                class="g-image"
                style="margin-left: -70px"
              />
            </v-col>
            <v-col lg="2" cols="6" md="3" sm="4">
              <img
                src="../assets/global/3.svg"
                class="g-image"
                style="width: 250px; margin-left: -40px"
              />
            </v-col>
            <v-col lg="2" cols="6" md="3" sm="4">
              <img
                src="../assets/global/4.png"
                class="g-image"
                style="width: 150px !important; margin-left: 10%"
              />
            </v-col>
            <v-col lg="2" cols="6" md="3" sm="4">
              <img
                src="../assets/global/5.svg"
                class="g-image"
                style="width: 250px"
              />
            </v-col>
          </v-row>
          <div class="global-data-mobile" v-else>
            <img
              src="../assets/global/1.svg"
              class="g-image"
              style="width: 80px !important"
            />
            <img src="../assets/global/2.png" class="g-image" />
            <img src="../assets/global/3.svg" class="g-image" />
            <img src="../assets/global/4.png" class="g-image" />
            <img src="../assets/global/5.svg" class="g-image" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "Team",
  computed: {
    isMobileView: function () {
      return window.innerWidth <= 760 ? true : false;
    },
    ...mapState({
      isPopup: (state) => state.isPopup,
    }),
  },
};
</script>
<style scoped>
.team-container {
  width: 100%;
  overflow: hidden;
  padding-top: 10%;
}

.team-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.team-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  font-size: 50px !important;
  text-align: center;
  font-family: "Orbitron", sans-serif !important;
}

.sub-title {
  width: 85%;
  margin: 10px 0px 10px 0px;
  text-align: center;
  font-family: "Chakra Petch", sans-serif;
  color: #e0e0e0;
  font-size: 22px;
  font-weight: 500;
}

.sub-title-2 {
  width: 60%;
  margin: 10px 0px 10px 0px;
  text-align: center;
  font-family: "Chakra Petch", sans-serif;
  color: #e0e0e0;
  font-size: 20px;
  font-weight: 500;
}

.team-div {
  height: 400px;
  background: rgba(255, 255, 255, 0.12);
  backdrop-filter: blur(3.27402px);
  border-radius: 25px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  position: relative;
}

.section-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.space {
  width: 40%;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  margin-top: 20px;
}

.team-section {
  height: 400px;
  backdrop-filter: blur(3.27402px);
  border-radius: 25px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  padding: 0px;
  position: relative;
  margin-top: 15%;
  margin-bottom: 20%;
}

.team-section > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.name {
  font-size: 22px;
  text-align: center;
  margin-top: 20px;
}

.desig {
  text-align: center;
  font-family: "Chakra Petch", sans-serif;
  font-weight: 800;
  color: rgba(255, 255, 255, 0.5);
  font-size: 18px;
  margin: 0px;
}

.old-work {
  text-align: center;
  font-family: "Chakra Petch", sans-serif;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
  font-size: 18px;
  margin: 0px;
}

.clg {
  text-align: center;
  font-family: "Chakra Petch", sans-serif;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
  font-size: 18px;
  margin: 0px;
}

.global-media {
  width: 100%;
  margin-top: 50px;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.heading {
  font-size: 22px;
  color: #e0e0e0;
  text-align: center;
  font-family: "Chakra Petch", sans-serif;
  font-weight: 500;
}

.global-data {
  width: 80%;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-left: -10%;
}

.g-image {
  height: 70%;
  width: 200px;
}
.header {
  background: linear-gradient(90.45deg, #00ffbf -2.93%, #1657dc 95.78%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-family: "Orbitron", sans-serif !important;
  font-weight: 800;
}
@media only screen and (max-width: 600px) {
  .title {
    font-size: 30px !important;
    text-align: center;
    font-family: "Orbitron", sans-serif !important;
    font-weight: 800 !important;
  }

  .sub-title {
    width: 80%;
    margin: 10px 0px 0px 0px;
    text-align: center;
    font-family: "Chakra Petch", sans-serif;
    color: #e0e0e0;
    font-size: 18px;
    font-weight: 400;
  }

  .global-data {
    width: 80%;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }

  .g-image {
    height: 30%;
    width: 150px;
    margin: 0px 15px 0px 15px !important;
    flex-shrink: 0;
    flex-grow: 0;
  }

  .space {
    width: 60%;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
    margin-top: 5px;
  }

  .global-data {
    width: 90%;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }

  .global-data-mobile {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    overflow-y: hidden;
    overflow-x: scroll;
    margin-top: 30px;
  }
}
</style>
